.img-ppal {
  width: 90%;
  position: relative;
}

.text-ppal-page {
  font-weight: 700;
  font-size: 2.5rem;
  color: white;
  text-align: center;
  font-family: "Roboto";
}

.search-btn-home {
  border-color: #fff !important; /* Corregir el color del borde */
  background: #fff !important;
  color: #a5b4cb !important;
  border-radius: 100px !important;
  font-weight: 400 !important;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.08) !important;
}

.container-home {
  width: 100%;
  background-image: url(../assets/SpecialitiesImage.png);
  background-size: cover;
  background-position: initial;
  background-repeat: no-repeat;
  background-color: #fff2;
  padding-top: 10rem;
  padding-bottom: 4rem;
}

.div-lateral {
  height: 100vh;
  width: 20%;
  box-shadow: -16px 0px 34px rgba(176, 179, 189, 0.1);
}

.container-body-home {
  overflow: auto;
  margin-top: 5%;
}

.volver-btn {
  width: 151px !important;
  height: 40px !important;
  border: 1px solid #006aff !important;
  background: white !important;
  color: #006aff !important;
  border-radius: 50px !important;
  margin-right: 5px !important;
}

.btnConfirm {
  background-color: #006aff !important;
  border-radius: 20px !important;
  width: 18% !important;
  height: 40px !important;
}

.ant-select-item-option-active:hover {
  background-color: #006aff !important;
  color: white;
}

.container-body-videocall {
  position: fixed;
  background-color: transparent;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Roboto";
}

/* ex .container-home.  Se copio la clase, solo se cambio la imegen */
.container-immediateVideoCall {
  width: 100%;
  background-image: url(../assets/Inmediata.png);
  background-size: contain;
  background-position: initial;
  background-repeat: no-repeat;
  background-color: #fff2;
  height: auto;
  min-height: 100vh;
}

.full-img {
  background-image: url(../assets/Inmediata.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-header-speciality {
  margin-top: -5%;
}

.ant-modal-header .ant-modal-title {
  font-weight: 700;
  font-size: 1.6rem;
}

.span-modal {
  font-weight: 400;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.35);
}

.ant-modal-header {
  border: none;
}

.input-modal {
  font-weight: 400;
  font-size: 1.3rem;
}

.modal-choose-date {
  width: 32% !important;
}

.vivi-modal-btn {
  height: 40px !important;
  border-radius: 50px !important;
}

.btn-ok {
  border: 1px solid #006aff !important;
  color: white !important;
  background: #006aff !important;
  opacity: 80%;
}

.btn-ok:hover {
  /* font-weight: bold; */
  opacity: 100%;
}

.btn-back {
  border: 1px solid #006aff !important;
  color: #006aff !important;
  background: white !important;

  opacity: 80%;
}

.div-dropdown {
  padding: 0% 35%;
}

.selectEspecialities .ant-select-selection-placeholder {
  padding-left: 10px !important;
  padding-top: 5px !important;
  font-size: medium;
}

.selectEspecialities .ant-select-selection-item {
  padding-left: 10px !important;
  padding-top: 5px !important;
}

.selectEspecialities .ant-select-selection {
  padding-left: 35% !important;
  padding-top: 5px !important;
}

.selectEspecialities .ant-select-selection-search-input {
  padding-left: 10px !important;
  padding-top: 5px !important;
}

.containerCardsDoctors {
  padding-left: 20%;
  padding-right: 20%;
}

@media (min-width: 1024px) {
  .modal-confirm-date {
    width: 32% !important;
  }

  .container-header-speciality {
    margin-top: -2%;
  }

  .container-body-home {
    margin-top: 2%;
  }
}

@media (min-width: 1920px) {
  .container-body-videocall {
    width: 56%;
  }
}

@media (max-width: 1680px) {
  .container-body-videocall {
    width: 52%;
  }
}

@media (max-width: 1024px) {
  .text-ppal-page {
    font-weight: 700;
    color: white;
    text-align: center;
    /* margin-left: 1rem; */
  }
}

@media (max-width: 599px) {
  .text-ppal-page {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .div-dropdown {
    padding: 0% 10% !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .text-ppal-page {
    font-size: 1.6rem;
  }
}

@media (max-height: 600px) {
  .container-body-home {
    margin-top: 2%;
  }

  .container-header-speciality {
    margin-top: -2%;
  }
}

@media (max-width: 768px) {
  .container-home {
    width: 100%;

    background-position: center;

    padding-top: 4rem;
  }
  .containerCardsDoctors {
    padding-left: 5px;
    padding-right: 5px;
  }
}
