.emergency-btn {
  height: 48px !important;
  width: 48px !important;
  border: none !important;
  border-radius: 100% !important;
  background-color: #11d4d4 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.calendar-btn {
  height: 48px !important;
  width: 48px !important;
  border: none !important;
  border-radius: 100% !important;
  /* font-weight: 500; */
  background-color: #006aff !important;
  /* background-size: 25px !important;
    background-repeat: no-repeat !important;
    background-position: center !important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 1rem;
}

.ant-collapse-expand-icon .ant-collapse-arrow {
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  height: 8vh;
  text-align: end;
  cursor: pointer;
  margin-left: 1%;
}

.div-card-medico {
  display: flex;
}

.div-collapse {
  margin-right: -2.5rem !important;
  margin-top: -1.5rem !important;
}

@media (min-width: 1025px) {
  .avatar-card-select-doctor {
    width: 40px !important;
    height: 40px !important;
  }
}

@media (max-width: 1024px) {
  .ant-collapse-expand-icon .ant-collapse-arrow {
    right: 22px;
  }

  .div-card-medico {
    align-items: center;
  }

  .avatar-card-select-doctor {
    width: 55px !important;
    height: 55px !important;
  }
}

@media (max-width: 667px) {
  .div-collapse {
    margin-top: -2.5rem !important;
  }
}

@media (min-width: 668px) {
}

.avatar-card-select-doctor {
  width: 50px !important;
  height: 50px !important;
}

/* Modificación para la flecha hacia abajo */
.ant-collapse-expand-icon .ant-collapse-arrow {
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  height: 100%; /* Ajuste para centrar verticalmente */
  display: flex; /* Permitir centrar verticalmente */
  align-items: center; /* Centrar verticalmente */
  justify-content: flex-end; /* Alinear la flecha hacia la derecha */
  cursor: pointer;
  margin-left: 1%;
}
