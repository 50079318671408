.itemContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container-item-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  border: 1px solid #e6e6e6;
}

.btn-appointment {
  color: #006aff;
  border-radius: 20px !important;
  padding-left: 14px !important;
  padding-right: 22px !important;
}

.container-turnos {
  width: 100%;
  margin-left: 4rem;
  margin-top: -4rem;
}

.turnospasados {
  width: 94%;
  border-radius: 10px !important;
  padding: 1rem !important;
}

.card-medico {
  border-radius: 10px !important;
  margin-top: 1rem !important;
}

.card-proximos-turnos {
  width: 94%;
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #006aff;
  pointer-events: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #006aff !important;
  font-weight: bold !important;
  text-shadow: 0 0 0.25px currentcolor;
  font-family: "Roboto";
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #006aff !important;

  pointer-events: none;
}

.ant-btn-primary {
  color: #fff;

  background: #0048ff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  opacity: 80%;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  opacity: 100%;
}

.containerAppointments {
  /* width: 70%; */
  display: flex;
  height: "100%";
}

.proxConsulta {
  font-size: 1.2rem;
  font-weight: bold;
}

.cardInfo {
  display: flex;
  flex-direction: row;
}

.cardContainer2 {
  padding: 1rem;
  background: #006aff87;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  min-width: 140px;
}

.dateAppointment {
  display: flex;
  flex-direction: row;
  color: #006aff;
  font-weight: bold;
  font-size: 1rem;
}

.container_title {
  margin-left: 4rem;
  position: absolute;
  margin-top: 3rem;
}

.div-turnos-pasados {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .itemContainer {
    flex-direction: column;

    /* width: 50%; */
  }

  .container-item-button {
    display: flex !important;
    flex-direction: column !important;

    align-items: center !important;
    border: 1px solid #e6e6e6;
  }

  .div-turnos-pasados {
    display: grid;
  }
}

@media (max-width: 667px) {
  .containerAppointments {
    width: 100%;
    display: flex;
    height: "100%";
  }

  .proxConsulta {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .dateAppointment {
    display: flex;
    color: #006aff;
    font-weight: bold;
    font-size: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
  }

  .container_title {
    margin-left: 1rem;
    position: absolute;
    margin-top: 3rem;
  }

  .cardContainer2 {
    padding: 1rem;
    background: #006aff87;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
  }

  .container-turnos {
    width: 100%;
    margin-left: 1rem;
    margin-top: -4rem;
  }

  .turnospasados {
    width: 100%;
    border-radius: 10px !important;
    padding: 1rem !important;
  }

  .card-medico {
    padding: 2rem !important;
  }
}

.containerCardsAppoiments {
  padding-left: 20%;
  padding-right: 20%;
}

@media (min-width: 668px) {
  .card-medico {
    padding: 3rem !important;
    margin-left: 12% !important;
    margin-right: 12% !important;
  }
}

@media (min-width: 1025px) {
  .div-turnos-pasados {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .containerCardsAppoiments {
    padding-left: 0px;
    padding-right: 0px;
  }

  .containerPacienteName {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .containerPacienteName span {
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

/* Modificaciones para dispositivos móviles */
