@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.dt-notifications {
  overflow: auto;
  border-radius: 10px;
}

.separado thead tr #Fecha {
  width: 20%;
}

.separado thead tr #Título {
  width: 20%;
}

@media (min-height: 700px) {
  .dt-notifications {
    height: 70vh !important;
  }
}

@media (min-height: 600px) {
  .dt-notifications {
    height: 62vh;
  }
}

@media (min-width: 1425px) {
  .dt-notifications {
    display: flex;
  }
}

.tabPaneFont {
  font-family: "Roboto" !important;
  /* color: "red" !important; */
}
