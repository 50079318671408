.remote-participants {
  position: relative;
  padding: 8% 0% 0% 6%;
}

.remote-video {
  width: 80%;
  height: 87%;
  object-fit: cover;
  border-radius: 14px;
  border: 0px solid white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: block;
  max-height: 450px;
}

.Noremote-participants {
  width: 100%;
  background-color: black;
  border: 0px solid white;
  height: 60vh;
}

.local-participant {
  position: absolute;
  width: 18%;
  right: 5%;
  bottom: 20%;
}

.containerVideoCall {
  display: flex;
  flex-direction: row;
}

.local-video {
  border: 3px solid white;
  object-fit: cover;
}

.bottom-barra-horizontal {
  background-color: #414141;
  padding-bottom: 1%;
  padding-top: 1%;
  width: 100%;
}

.buttonVideoCall {
  border-radius: 35px !important;
  font-size: x-large !important;
  margin-left: 1rem !important;
  height: 100% !important;
}

.div-sider-column {
  height: 100%;
  box-shadow: -16px 0px 34px rgba(176, 179, 189, 0.1);
}

.div-header-videocall {
  height: 5%;
  display: flex;
  align-items: center;
}

.div-hour-videocall {
  margin-left: 5%;
  width: 20%;
}

.div-buttons-videocall {
  text-align: end;
  width: 60%;
}

.div-patient-videocall {
  display: flex;
  padding: 2% 0% 0% 1%;
}

.div-col-videocall {
  width: 70%;
  height: 100%;
}

.div-col-sider-appointments {
  width: 30%;
}

.div-name-participant {
  position: absolute;
  bottom: 20%;
  color: white;
  margin-left: 5%;
  text-transform: capitalize;
}

.div-video-videocall {
  margin-top: 4%;
}

.col-buttons-videocall {
  text-align: end;
}

@media (min-width: 1025px) {
  .local-participant2 {
    right: 3% !important;
    bottom: 13% !important;
  }

  .centerButtonsFullScreem {
    padding-right: 8.5rem !important;
  }
}

@media (max-width: 1024px) {
  .bottomBarraHorizontal2 {
    height: 16% !important;
  }

  .local-participant2 {
    right: 3% !important;
    bottom: 13% !important;
  }

  .div-sider-column {
    display: none;
  }

  .div-header-videocall {
    display: none;
  }

  .div-hour-videocall {
    width: 100%;
  }

  .div-buttons-videocall {
    width: 90%;
  }

  .remote-participants {
    padding: 0%;
  }

  .col-buttons-videocall {
    text-align: center;
  }

  .remote-video {
    height: 91vh;
    max-height: max-content;
  }

  .div-name-participant {
    bottom: 8%;
  }
}

@media (max-width: 1200px) {
  .div-data-footer {
    display: none;
  }

  .div-col-sider-appointments {
    width: 0%;
  }

  .remote-participants {
    padding: 1% 0% 0% 0%;
  }
}

@media (max-width: 768px) {
  .containerVideoCall {
    flex-direction: column !important;
  }
}

@media (max-width: 500px) {
  .div-name-participant {
    bottom: 8% !important;
  }

  .local-participant {
    bottom: 8% !important;
  }
}

@media (max-width: 1000px) {
  .div-name-participant {
    bottom: 18%;
  }
}

@media (max-height: 500px) {
  .Noremote-participants {
    height: 76vh;
  }
}

@media (min-height: 501px) and (max-height: 670px) {
  .Noremote-participants {
    height: 88vh;
  }

  .local-participant {
    width: 30%;
    bottom: 12%;
  }

  .div-name-participant {
    bottom: 12%;
  }
}

@media (min-height: 500px) and (max-height: 1500px) {
  .Noremote-participants-fullscreen {
    height: 92vh;
  }

  .div-video-videocall {
    margin-top: 0%;
  }
}

@media (min-height: 961px) and (max-height: 1024px) {
  .Noremote-participants {
    height: 52vh;
  }
}

@media (min-height: 1025px) and (max-height: 1200px) {
  .Noremote-participants {
    height: 45vh;
  }
}
