.admin-sider {
  background-color: #fff !important;
  box-shadow: inset -1px 0px 0px #dfe5f1;
  flex: none !important;
  min-width: 0px !important;
}

.nav-text {
  font-weight: 500;
  font-size: 1rem;
  margin-left: 5px;
  font-family: "Roboto";
}

.dividerClass {
  border: 1.05409px solid #dfe5f1;
}

.btn-logout {
  border: none !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
}

.name {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
}

.menu-text {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.ModalEmergency {
  width: 100% !important;
  max-width: 660px !important;
}

.ModalEmergency .ant-modal-header {
  border-bottom: none !important;
}

.ModalEmergency .ant-modal-header .ant-modal-title {
  text-align: center;
}

.ModalEmergency .react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  margin-left: 0;
}

.ModalEmergencyViewAppoiment {
  width: 26% !important;
}

.ModalEmergencyViewAppoiment .ant-modal-header {
  border-bottom: none;
}

.ModalEmergencyViewAppoiment .ant-modal-body {
  padding-top: 0;
}

.ModalEmergencyViewAppoiment .ant-modal-title {
  font-weight: 700;
  font-size: large;
  padding: 1rem;
  text-align: center;
}

.ant-modal-content,
.ant-modal-header {
  border-bottom: none !important;
}

.ant-modal-footer {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #006aff !important;
}

.ant-menu-item-selected a {
  color: #006aff !important;
}

.div-icon-user {
  display: flex;
  width: 100%;
}

.logo-vivi {
  height: 40px;
  display: block;
}

.logo-vivi-mobile {
  height: 35px;
  display: none;
}

.div-btn-logout {
  position: absolute;
  width: 100%;
  text-align: -webkit-center;
  bottom: 0%;
}

.div-logos {
  padding-top: 20px;
  padding-bottom: 20px;
}

.div-divider {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.div-btn-close-sesion {
  position: absolute;
  padding-left: 20px;
  padding-bottom: 20px;
  bottom: 0;
}

.div-btn-close-sesion .btn-logout.ant-btn {
  border: none !important;

  box-shadow: none !important;
}

.menu-slider li span a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.admin-sider {
  max-width: 250px !important;
}

.menu-slider {
  padding-left: 1rem !important;
}

@media (max-width: 768px) {
  .menu-slider {
    padding-left: 0px !important;
  }
}

@media (max-height: 500px) {
  .menu-slider {
    height: 100vh;
  }
}

@media (min-height: 501px) {
  .admin-sider {
    height: 100vh;
  }
}

@media (max-width: 1020px) {
  .admin-sider {
    width: 12% !important;
  }

  .logo-vivi {
    display: none;
  }

  .logo-vivi-mobile {
    display: block;
  }

  .btn-logout {
    width: 50%;
  }
}

@media (max-width: 1299px) {
  .div-icon-user {
    justify-content: center;
  }

  .div-logos {
    text-align: -webkit-center;
  }
}

@media (min-width: 1300px) {
  .div-icon-user {
    padding-left: 20px;
  }

  .admin-sider {
    width: 25% !important;
  }

  .div-logos {
    padding-left: 20px;
  }

  .div-btn-logout {
    bottom: 0px;
  }
}

@media (max-width: 376px) {
  .div-btn-close-sesion {
    bottom: 0px;
  }
}

@media (min-width: 1000px) {
  .admin-sider {
    min-width: 250px !important;
  }
}
