.label-form-contactus {
  color: #c5c8d3;
  font-size: 1.1rem;
  font-weight: 400;
}

.btn-enviar {
  height: 40px !important;
  background-color: #006aff !important;
  color: white !important;
  border-radius: 50px !important;
  opacity: 80%;
}

.btn-enviar:hover {
  opacity: 100%;
}

.row-without-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 991px) {
  .padding-contact-us {
    padding: 0rem 1rem;
  }
}

@media (min-width: 992px) {
  .padding-contact-us {
    padding: 0rem 10rem;
  }

  .btn-enviar {
    width: 12%;
  }
}
