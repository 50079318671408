.calendarModal {
  border: none !important;
}

.containerModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.divisor {
  width: 1px;
  height: 270px;
  background-color: #c3c3c3;
  margin-left: 50px;
}

.select-option-calendar {
  background-color: #eeeef0;
  border-radius: 10px;
  padding: 6%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btnAgendar {
  border-radius: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #006aff !important;
  margin-left: 10px;

  opacity: 80%;
}

.btnCancel {
  border-radius: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-color: black !important;
  /* color: #4177C6 !important;  */
}

.react-calendar__tile--now {
  background-color: transparent !important;
  border-radius: 100% !important;
  color: #006aff !important;
  font-weight: bold;
}

.ant-switch-checked {
  background-color: #006aff !important;
}

@media (max-width: 768px) {
  .divisor {
    margin-top: 20px;
    width: 80%;
    height: 1px;
    margin-bottom: 20px;
  }

  .select-option-calendar {
    padding-left: 0px;
  }

  .calendarModal {
    width: 100% !important;
  }
}

@media (min-width: 599px) {
  .div-container-modal {
    width: 45%;
    height: 249px;
  }
}

@media (max-width: 600px) {
  .select-option-calendar {
    width: 100%;
    margin-top: 5%;
  }

  .containerModal {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
