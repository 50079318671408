@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.container-profile {
  height: 100%;
  background-color: #f6f7f9;
}

.containerMiPerfil {
  margin-left: -4.2rem;
}

.card-profile-1 {
  background: white;
  border-radius: 10px;
  padding: 1rem;
}

.btn-changePassword {
  display: flex;
  justify-content: left;
  margin-top: 1rem;
  margin-left: 2.5rem;
  cursor: pointer;
}

.btn-changePassword:hover {
  color: #006aff !important;
}

.card-profile-2 {
  background: white;
  border-radius: 10px;
  height: 100%;
  padding: 1rem 2rem;
}

.btn-save {
  color: white !important;
  background: #006aff !important;
  border-radius: 50px !important;
  opacity: 80%;
}

.btn-save:hover {
  opacity: 100%;
}

.btn-cancel {
  border: 1px solid #4299e1 !important;
  color: #4299e1 !important;
  background: white !important;
  border-radius: 50px !important;
  opacity: 80%;
}

.btn-cancel:hover {
  opacity: 100%;
}

.ant-picker {
  border-radius: 16px !important;
  width: 100%;
  margin-top: 6px !important;
}

.ant-picker-suffix {
  color: #006aff !important;
}

.ant-checkbox-inner {
  border-radius: 15px !important;
  margin-top: 6px !important;
}

.my-profile {
 padding-top: 2%;
 padding-left: 6rem;
}

.name-profile h3 {
  text-transform: capitalize;
  justify-content: center;
  margin-top: 1.5rem;
  font-family: Roboto;
  /* font-weight: bold; */
  font-size: 1.6rem;
  text-align: center;
  color: #333; /* Color del texto */
}

.span-class {
  display: flex;
  margin-top: 10px;
  font-size: medium;
  font-family: "Roboto";
}

.avatar {
  border: 3px solid #006aff;
  padding: 2px;
  border-radius: 100%;
}

.col-card-profile-1 {
  padding: 2rem 1rem 0rem 6rem;
}

.col-card-profile-2 {
  padding: 2rem 20rem 0rem 2rem;
}

@media (max-width: 768px) {
  .container-profile {
    height: auto;
  }
}

@media (min-width: 1600px) {
  .menuItemPerfil {
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.1rem;
  }
}

@media (min-width: 1366px) {
  .container-profile {
    overflow: auto;
  }
}

@media (max-width: 1024px) {
  .card-profile-2 {
    padding-left: 20px;
  }

  .card-profile-1 {
    padding: 1rem;
  }

  .col-card-profile-1 {
    padding: 2rem 1rem 0rem 1rem;
  }

  .col-card-profile-2 {
    padding: 2rem 1rem 0rem 1rem;
  }

  .my-profile {
    padding-left: 1rem;
  }
}

@media (max-width: 768px) {
  .card-profile-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .btn-changePassword {
    justify-content: center !important;
    margin-left: 0px;
  }
}

.modalSmall {
  max-width: 20rem !important;
}

@media (max-width: 768px) {
  .containerMiPerfil {
    margin-left: 0px;
  }
}
