.span-text-title {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Roboto";
}

.row-main-admin {
  background-color: #fff;
  height: 100vh;
}

@media (min-height: 501px) {
  .admin-content {
    height: 100vh;
  }
}
