.list-users {
  height: 100%;
  overflow: auto;
}

.list-users__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}

.list-users__header-switch {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 10px;
}

.users-active {
  background-color: transparent;
  padding: 10px 20px;
}

.ant-list-item-meta {
  display: flex;
  align-items: center;
}

.list-users__header-switch-i {
  visibility: hidden;
}

.dt-users {
  overflow: auto;
  border-radius: 10px;
  padding: 1rem;
}

@media (min-height: 700px) {
  .dt-users {
    height: 75vh !important;
  }
}

@media (min-height: 600px) {
  .dt-users {
    height: 67vh;
  }
}

@media (min-width: 1425px) {
  .dt-users {
    display: flex;
  }
}

@media (max-width: 768px) {
  .list-users__header Button {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .list-users__header-switch span {
    font-size: 0.8rem;
  }
}

table.separado {
  /* font-size: 20px; */
  width: 100%;
}

.separado {
  /* border: 1px solid black; */
  background-color: #f0f2f5;
  border-radius: 10px;
}

.separado tbody {
  text-align: start;
}

.separado thead th {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  text-align: start;
}

.btnAnularCuenta {
  color: red !important;
  border-color: red !important;
  border-radius: 20px !important;
}

.btnDownload {
  border: none !important;
  color: #006edc !important;
  background-color: transparent !important;
  font-size: 1rem !important;
  border-radius: 20px !important;
  font-weight: 500;
  padding-left: 0px !important;
  padding-top: 2% !important;
}

.btnAprobar {
  color: #fff !important;
  border-color: #4177c6 !important;
  background-color: #4177c6 !important;
  border-radius: 20px !important;
  opacity: 80%;
}

.btnAprobar:hover {
  opacity: 100%;
}
