@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Poppins:wght@600&display=swap");

.landing-page {
  background: #f7f7f7;
  height: 100vh;
  overflow: auto;
}

.footerText {
  font-family: "Inter", sans-serif;
}

.landing-header {
  height: 10rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-landing {
  height: 50.46px;
  width: 150.74px;
  margin: 1%;
}

.landing-content {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.jWpUJH {
  bottom: -80px !important;
}

.login-registration {
  text-align: right;
  position: relative;
  font-size: medium !important;
  padding-right: 30px !important;
  padding-top: 50px;
  color: #006aff;
  font-weight: bold;
}

.vivi-text {
  font-size: xx-large;
  margin-left: -15px;
}

.card {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  height: 27rem;
  margin-top: 2rem;
  width: 47rem;
}

.site-card-wrapper {
  position: absolute;
  margin-top: 48rem;
  width: 82%;
}

.card-landing {
  border-radius: 20px !important;
  background: #fff !important;
  border: 1px solid #dee6ea !important;
}

.card-small {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  height: 319px;
  margin-top: 8rem;
  width: 75%;
  position: absolute;
}

.title-card {
  text-align: center;
  font-weight: bold;
  font-size: x-large;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.body-card {
  text-align: center;
}

.card2 {
  border: 1px solid black;
  height: 31rem;
  width: 55%;
  text-align: center;
  margin-top: 37px;
  margin-bottom: 4rem !important;
}

.btn-registrarme {
  border: 1px solid #4177c6 !important;
  background: #4177c6 !important;
  border-radius: 16px !important;
  color: white !important;
  justify-content: center;
}

.btn-login-header {
  color: black !important;
  font-weight: normal;
  margin-right: 10px;
}

.titleOne {
  font-size: 2rem;
  font-weight: 700;
}

.FullcontainerOne {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20vh;
  padding-right: 20vh;
}

.titleTwo {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 55px;
}

.opinions-area {
  padding: 2rem;
  /* background: linear-gradient(
    260.69deg,
    #11d4d4 -83.7%,
    #0090d7 -18.03%,
    #006aff 48.98%,
    #11d4d4 173.61%
  ); */
  width: 100%;
}

.containerCards {
  display: flex;
  flex-direction: row;
}

.containerCard {
  align-items: center;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: normal;
  list-style: none;
  overflow: hidden;
  flex-direction: column;
  padding: 3%;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
  text-decoration: none;
  transition: box-shadow 0.5s, transform 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: box-shadow, transform;
}

.containerCard:focus {
  box-shadow: #000000 0 0 0 10px inset, rgba(45, 35, 66, 0.4) 0 2px 10px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.containerCard:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px);
}

.containerCard:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.containerCardTwo {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
  width: 70vh;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  border-width: 0;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  transition: all 0.3s ease;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow 0.5s, transform 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.containerCardTwo:focus {
  box-shadow: #000000 0 0 0 10px inset, rgba(45, 35, 66, 0.4) 0 2px 10px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.containerCardTwo:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px);
}

.containerCardTwo:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.cardElijeMedico {
  padding-right: 8rem;
  padding-top: 4rem;
  padding-left: 11rem;
}

.imgCalendar {
  padding-left: 10rem;
}

.containerImgPagos {
  padding: 7rem;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
}

.legend {
  font-size: 2rem;
  color: black;
}

.prevCard {
  left: 0;
}

.activeCard {
  left: 50%;
  transform: translateX(-50%);
}

.nextCard {
  right: 0;
}

.card-container {
  position: relative;

  width: 36rem;
  height: 22rem;
}

.leftLogo {
  cursor: pointer;
}

.imagenTrasnferencia {
  max-width: 60px;
  max-height: 60px;
}

.imagenPagoMovil {
  /* max-width: 60px;
  max-height: 60px;
  margin-left: 1.7rem; */
  height: 90px;
}

#logo {
  font-size: 1.5rem;
  font-weight: bold;
}

#menu {
  display: flex;
  list-style: none;
  gap: 0.5rem;
  align-items: center;
}

#menu a {
  display: block;
  padding: 0.5rem;
}

#btn-mobile {
  display: none;
}

.imagenPagoZelle {
  margin-top: 1.2rem;
}

.imagenPagoPaypal {
  margin-top: 1.5rem;
}

.imagenPagoVisa {
  margin-top: 0.5rem;
}

.imagenPagoMaster {
  margin-top: 0.7rem;
}

.imagenPagoMaestro {
  margin-top: 0.5rem;
}

.span-size {
  font-family: "Inter", sans-serif;
  font-size: 3vh;
  font-weight: 400;
  line-height: 4vh;
  opacity: 60%;
}

.landing-hijo {
  height: 85vh;
}

.span-card {
  font-size: 1rem;
  font-weight: 400;
}

.span-card-pro {
  line-height: 4 !important;
}

.cardImage {
  object-fit: cover;
  height: 250px;
  width: 250px;
}

.cardImage2 {
  object-fit: cover;
  height: 273px;
  width: 250px;
}

/* H1 */
.span-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 6vh;
  line-height: 8vh;
}

.span-header {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 24.2px;
}

.button-in {
  display: inline-flex;
  padding-left: 3vh;
  opacity: 80%;
}

.button-in:hover {
  opacity: 100%;
}
.btnIngresar {
  width: 150px;
  height: 40px;
  opacity: 60%;
  background-color: transparent;
  margin-left: 1rem;
  color: black;
  font-size: initial;
  cursor: pointer;
  border: none !important;
  margin-left: 5px;
}

.btnIngresar:hover {
  opacity: 100%;
}
.header-link {
  text-decoration: none;
  color: #333;
  position: relative;
  transition: color 0.3s, border-bottom 0.3s;
}

.header-link:hover {
  color: #006aff;
}

.header-link:after {
  content: "";
  display: block;
  border-bottom: 2px solid #006aff;
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-link:hover:after {
  opacity: 1;
}

/* Botones del medio  */

.button-middle {
  align-items: center;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  transition: all 0.3s ease;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.5s, transform 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  /* font-size: 18px; */
}

.button-middle:focus {
  box-shadow: #000000 0 0 0 10px inset, rgba(45, 35, 66, 0.4) 0 2px 10px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-middle:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px);
}

.button-middle:active {
  box-shadow: #111114 0 3px 7px inset;
  transform: translateY(2px);
}

.button-30 {
  align-items: center;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  border-width: 0;

  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  transition: all 0.3s ease;
  height: 30px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.5s, transform 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1.2rem;
}

.button-30:focus {
  box-shadow: #000000 0 0 0 10px inset, rgba(45, 35, 66, 0.4) 0 2px 10px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.div-logo-landing {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.row-without-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.containerOne {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.containerNav {
  text-align: end;
}

.row-howuse {
  background-color: #fff;
  padding: 3rem 0rem;
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 3.5vh;
}
.containerSectionPagos {
  height: 50vh;
}

.TitleSectionPago {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

/* MEDIA QUERY DESK  */
@media (max-width: 799px) {
  .landing-hijo {
    width: 90%;
  }

  .card-landing-col {
    padding-top: 2rem;
  }

  .card-landing-img {
    padding: 1rem;
  }

  .row-search-speciality {
    padding-top: 3rem;
  }

  .col-contact {
    padding: 4rem 1rem;
  }
}

@media (min-width: 800px) {
  .card-landing {
    padding: 2rem 0rem !important;
  }

  .card-landing-img {
    height: 100%;
  }

  .col-contact {
    padding: 4rem 0rem;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .landing-hijo {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .containerCardLanding {
    height: 100% !important;
    flex-direction: column-reverse;
    justify-content: center !important;
    align-items: center;
  }

  .card-landing-img {
    width: 200px !important;
  }

  .containerSectionPagos {
    height: auto;
    text-align: center;
  }

  .TitleSectionPago {
    font-size: 35px !important;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  .card-title,
  .span-size {
    text-align: center;
  }

  .subtitleLanding {
    text-align: start;
  }

  .containerCardsCentrales {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  #menu {
    display: block;
    position: absolute;
    width: 100%;
    top: 70px;
    right: 0px;
    background: #e7e7e7;
    transition: 0.6s;
    z-index: 1000;
    height: 0px;
    visibility: hidden;
    overflow-y: hidden;
  }

  #nav.active #menu {
    height: calc(100vh - 70px);
    visibility: visible;
    overflow-y: auto;
  }

  #menu a {
    padding: 1rem 0;
    margin: 0 1rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  }

  #btn-mobile {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    gap: 0.5rem;
  }

  #hamburger {
    border-top: 2px solid;
    width: 20px;
  }

  #hamburger::after,
  #hamburger::before {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background: currentColor;
    margin-top: 5px;
    transition: 0.3s;
    position: relative;
  }

  #nav.active #hamburger {
    border-top-color: transparent;
  }

  #nav.active #hamburger::before {
    transform: rotate(135deg);
  }

  #nav.active #hamburger::after {
    transform: rotate(-135deg);
    top: -7px;
  }

  #atiendePacientes {
    margin-bottom: 1%;
  }

  .titleOne {
    font-size: 4vh;
  }

  .leftLogo {
    margin-left: 0px;
    padding-bottom: 1rem;
  }

  .landing-content {
    margin-top: 1rem;
  }

  .titleTwo {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 46px;
  }

  .FullcontainerOne {
    padding-left: 1rem;
    padding-right: 2rem;
  }

  .containerCards {
    display: flex;
    flex-direction: column;
  }

  .containerCard {
    margin-top: 5%;
  }

  .landing-header {
    padding-left: 1rem;
    padding-right: 2rem;
  }

  .containerNav {
    padding-right: 0 !important;
  }

  .containerCardTwo {
    margin-top: 1rem;
  }

  .cardElijeMedico {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  .imgAtiendePacientes {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
  }

  .imgCalendar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .containerImgPagos {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #formasDePago {
    padding-top: 2rem;
    width: 100%;
  }

  .div-logo-landing {
    margin-top: 2%;
  }

  .col-img-pago {
    padding-top: 1rem;
  }
}

@media (min-width: 1025px) {
  .landing-content {
    height: 85vh;
  }

  .landing-hijo {
    width: 38%;
  }

  .containerCard {
    width: 50%;
  }
}
