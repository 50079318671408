@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.textarea-input {
  background-color: white !important;
}

.text-container {
  padding-left: 1rem;
  padding-top: 2rem;
  padding-right: 1rem;
}

.extraClass {
  text-align: center;
  vertical-align: central;
  padding-top: 10rem;
  padding-bottom: 4rem;
}

.containerTextVideollamadaInmediata {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 2rem;
}

.textRoboto {
  font-family: Roboto !important;
}

@media (min-width: 440px) {
  /* .div-videocall {
        height: 26vh;
    } */
}
@media (min-width: 990px) {
  /* .div-videocall {
        height: 26vh;
    } */
}

@media (max-width: 1366px) {
  .container-text-ppal-page {
    display: contents;
  }
  .text-ppal-page {
    font-size: 2rem;
  }
   .div-videocall {
        height: 39vh;
    }
 }

@media (min-width: 1920px) {
  .textarea-input {
    min-height: 12rem !important;
  }

  /* .div-videocall {
        height: 42vh;
    } */
}

@media (min-width: 1366px) {
  .textarea-input {
    min-height: 9rem !important;
  }

  /* .div-videocall {
        height: 44vh;
    } */
}

@media (max-width: 768px) {
  .containerTextVideollamadaInmediata {
    padding-left: 5%;
    padding-right: 5%;
  }
}
