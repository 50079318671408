@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Poppins:wght@600&display=swap");

.reset-pass-main {
  background: #f7f7f7;
  height: 100vh;
}

.titleContactForm {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 56px;
}

.contact-page {
  border: 2px solid #f0f0f0;
  border-radius: 20px;
}

.row-margin {
  margin: 0px !important;
}

.label-form {
  color: #c5c8d3;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  line-height: 35px;
}

.input-backgroundcolor {
  background-color: #f7f7f7 !important;
}

@media (max-width: 799px) {
  .contact-page {
    padding: 1rem;
  }
}

@media (min-width: 800px) {
  .contact-page {
    padding: 3rem;
  }
}
