@import url("https://fonts.googleapis.com/css2?family=Avenir+Next:wght@300;400;500;700&display=swap");

.search-btn {
  border-color: #f2f2f2 !important;
  background: #f2f2f2 !important;
  color: #a5b4cb !important;
  width: 300px !important;
  border-radius: 39px !important;
  text-align: initial !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  height: 40px !important;
}

.title-reporte {
  margin-top: 2rem;
  font-weight: 900;
  font-size: 1.5rem;
}

.box-report {
  background: #fafafb;
  border-radius: 10px;
  height: 100%;
}

.icons {
  color: #7d8da6 !important;
  font-size: large !important;
}

.label-report {
  color: #808080;
  font-weight: lighter;
  margin-left: 2rem;
  font-family: "Roboto";
}

.card-report {
  background: white !important;
  width: 20%;
  margin: 1% !important;
  border-radius: 10px !important;
  padding: 1% !important;
}

.contenedor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5% 2% 1.5% 2%;
}

.pago {
  color: #808080;
  font-weight: lighter;
  padding-left: 10px;
}

.icon-circle-pago {
  color: #92de8b !important;
  background: #92de8b !important;
  border-radius: 10px !important;
  align-self: flex-start;
}

.pagination {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-right: 20px;
}

.next-prev {
  background: rgba(125, 141, 166, 0.1) !important;
  border-radius: 4px !important;
  border: rgba(125, 141, 166, 0.1) !important;
  width: 5% !important;
}

.div-right {
  box-shadow: 6px 0px 34px rgb(176 179 189 / 10%);
  width: 30%;
  display: flex;
  justify-content: center;
  height: 100% !important;
  flex-direction: row;
}

.semana-text {
  margin-right: 2rem;
  color: #7d8da6;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Roboto";
}

.btn-icons-pointer {
  border: 0px solid;
  background: transparent;
}

.span-info {
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.col-header-report {
  padding-left: 0px;
  padding-right: 0px;
}

.margin-left-right {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.padding-left-right {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 1024px) {
  .card-report {
    width: 100%;
    padding: 1rem !important;
  }

  .col-header-report {
    padding-top: 1rem;
  }

  .report-card-content {
    height: 76%;
  }
}

@media (min-width: 1025px) {
  .col-header-report {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }

  .report-card-content {
    height: 82%;
  }
}

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
