.span-class {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-family: "Roboto";
  padding: 0.5rem !important;
  border-radius: 10px !important;
}

.datos-personales {
  padding-top: 5px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 1.6rem;
}

.label-view {
  color: #c5c8d3;
  font-size: 1rem;
  font-weight: light;
  font-weight: 400;
  font-family: 'Roboto';
}

.register-form__input {
  font-family: Roboto;
}

.edit-btn {
  height: 30px !important;
  width: 80px !important;
  background-color: white !important;
  color: #006aff !important;
  border: 1px solid #006aff !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
  opacity: 80%;
}

.edit-btn:hover {
  opacity: 100%;
}

@media (max-width: 768px) {
  .containerButtonsEditProfile {
    justify-content: center !important;
  }
}
