.CalendarSelectReservation {
  border: none !important;
  box-shadow: -16px 0px 34px rgba(176, 179, 189, 0.1) !important;
  margin-top: 5%;
}

.volver-btn {
  width: 151px !important;
  height: 40px !important;
  border: 1px solid #4177c6 !important;
  background: white !important;
  color: #4177c6 !important;
  border-radius: 50px !important;
  margin-right: 5px !important;
  opacity: 80% !important;
}

.ant-list .ant-spin-nested-loading .ant-spin-container .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.btn-back:focus,
.btn-back:hover {
  /* color: #fff;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px); */
  opacity: 100%;
}

.div-calendar-sel-reservation {
  width: 100%;
}

@media (min-width: 1024px) {
  .div-calendar-sel-reservation {
    width: 44%;
  }
}

@media (min-width: 600px) {
  .div-calendar-modal {
    display: flex;
  }
}

.btn-ok-disabled {
  border: 1px solid gray !important;
  color: white !important;
  background-color: gray !important;
  background-color: gray !important;
  opacity: 80%;
}
