.card-side {
  border-radius: 8px !important;
  box-shadow: -2px 3px 10px rgb(0 0 0 / 8%);
  height: 5rem;
  margin-bottom: 5% !important;
}

.title-appo-side {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
  font-family: "Roboto";
}

.card-span {
  font-weight: 600;
  font-size: 1rem;
  display: flex;
}

.card-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-div-align {
  display: flex;
  align-items: center;
}

@media (min-width: 1920px) {
  .card-span {
    padding: 3% 10% 0% 10%;
  }

  .card-div {
    padding: 3% 10% 0% 10%;
  }

  .card-divide-info {
    margin-left: 15%;
  }

  .card-side {
    width: 70%;
    margin-left: 15% !important;
  }
}

@media (max-width: 1680px) {
  .card-span {
    padding: 3% 8% 0% 8%;
  }

  .card-div {
    padding: 3% 8% 0% 8%;
  }

  .card-divide-info {
    margin-left: 5%;
  }

  .card-side {
    width: 75%;
    margin-left: 12% !important;
  }
}
