.dt-transaction {
    overflow: auto;
    border-radius: 10px;
    padding: 1rem;
}

@media (min-height: 1024px) {
    .dt-transaction {
        height: 60vh !important;
    }
}

@media (min-height: 800px) and (max-height: 1023px) {
    .dt-transaction {
        height: 51vh !important;
    }
}

@media (min-height: 700px) and (max-height: 765px) {
    .dt-transaction {
        height: 60vh !important;
    }

    .div-container-transaction {
        height: 100vh;
    }
}

@media (min-height: 600px) {
    .dt-transaction {
        height: 40vh;
    }

    .div-container-transaction {
        height: 100vh;
    }
}

@media (max-height: 599px) {
    .dt-transaction {
        height: 40vh;
    }
}

@media (min-width: 1425px) {
    .dt-transaction {
        display: flex;
    }
}