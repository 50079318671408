@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.calendarRigth {
  width: 100% !important;
  border: transparent !important;
}

.rbc-row {
  /* min-height: 20px; */
  display: none;
}

.rbc-toolbar {
  margin-bottom: 0.5rem !important;
}

.rbc-header {
  padding: 20px 0px !important;
  border: none !important;
}

.rbc-timeslot-group {
  border: none !important;
}

.rbc-toolbar-label {
  text-transform: capitalize;
  display: flex;
  margin-left: 1rem;
  color: #7d8da6;
  font-weight: 400;
  font-size: 1rem;
}

.rbc-time-view {
  background: rgba(246, 247, 249, 0.6);
  border-radius: 10px;
  border: none !important;
  padding-left: 2%;
}

.react-calendar__tile--active {
  background-color: rgb(207, 220, 245) !important;
  color: black !important;
}

.react-calendar__tile--rangeStart {
  background-color: #006aff !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.react-calendar__tile--rangeEnd {
  background-color: #006aff !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.rbc-allday-cell {
  display: none;
}

.DeleteSchedule .ant-modal-header .ant-modal-title {
  text-align: center;
}

.ModalCalendar {
  width: 100% !important;
}

.ModalCalendar .ant-modal-header {
  border-bottom: none !important;
  padding: 5% 6%;
}

.ModalCalendar .react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  margin-left: 0;
}

.ModalCalendarViewAppoiment {
  width: 400px !important;
  height: 500px !important;
}

.ModalCalendarViewAppoiment .ant-modal-header {
  border-bottom: none;
}

.ModalCalendarViewAppoiment .ant-modal-body {
  padding-top: 0;
}

.ModalCalendarViewAppoiment .ant-modal-title {
  font-weight: 500;
  font-size: 1.3rem;
  padding: 1rem;
  text-align: center;
}

.ant-modal-content,
.ant-modal-header {
  border-radius: 12px !important;
}

.btn-agregate-hour {
  border-radius: 30px !important;
  /* background-color: #11d4d4 !important; */
  display: flex !important;
  align-items: center !important;
  opacity: 80%;
  color: #006aff !important;
  border: 1px solid #006aff !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  font-family: "Roboto";
}

.btn-agregate-hour:hover {
  /* box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25); */
  /* transform: translateY(-3px); */
  opacity: 100% !important;
}

.calendar-medico .rbc-event,
.rbc-day-slot .rbc-background-even {
  border: none !important;
  border-radius: 2px;
}

.calendar-medico .rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #9caeca;
  font-family: "Roboto";
}

.react-calendar__navigation__label {
  /* text-transform: capitalize !important; */
  color: #161736 !important;
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 600;
}

.react-calendar__navigation__label::first-letter {
  text-transform: capitalize !important;
}

.btn-cancel-delete {
  height: 40px !important;
  background: white !important;
  border-radius: 50px !important;
  margin-right: 3% !important;
  opacity: 80%;
}

.btn-confirm-delete {
  background-color: #006aff !important;
  border-radius: 20px !important;
  height: 40px !important;
  color: white !important;
}

.ModalCalendar .ant-modal-title {
  font-weight: 700;
  font-size: 1.3rem;
}

.rbc-time-content {
  border-top: 0px !important;
}

.rbc-time-header.rbc-overflowing {
  border-right: 0px !important;
}

.rbc-time-header-content {
  border-left: 0px !important;
}

.rbc-timeslot-group .rbc-time-slot .rbc-label {
  font-size: 0.9rem;
  font-weight: 400;
}

.rbc-events-container .rbc-event .rbc-event-label {
  margin-top: -2%;
  width: 100% !important;
  text-align: center;
}

.rbc-events-container .rbc-event {
  min-height: 15px !important;
  margin-left: 4%;
}

.modal-choose-date .ant-modal-content .ant-modal-body {
  padding: 0% 6% 2% 6%;
}

.modal-choose-date .ant-modal-content .ant-modal-header {
  padding: 4%;
}

.react-calendar__month-view__days__day--weekend {
  color: rgba(0, 0, 0, 0.85) !important;
}

.div-container-calendar {
  height: 100vh;
}

.div-container-right-calendar {
  flex-direction: column;
  box-shadow: -16px 0px 34px rgba(176, 179, 189, 0.1);
}

.span-calendar-date {
  align-self: center;
  font-family: "Roboto";
}

.div-calendar-navigate {
  width: 20%;
}

.headerCalendar {
  margin-left: -12px;
  margin-right: -12px;
}

.col-btn-headers {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-left: -1rem;
}

.calendar-medico {
  display: contents;
}

.col-header-calendar {
  display: flex !important;
  justify-content: flex-end;
}

@media (min-width: 1920px) {
  .div-container-calendar {
    padding: 2%;
  }

  .ModalCalendar .ant-modal-body {
    padding: 2% 6% 3% 6% !important;
  }
}

@media (min-width: 1025px) {
  .ModalCalendar {
    max-width: 690px !important;
  }

  .div-container-calendar {
    padding: 1%;
    width: 75%;
    height: 100vh;
  }

  .ModalCalendar .ant-modal-body {
    padding: 1% 6% 1% 6% !important;
  }

  .div-container-right-calendar {
    width: 25%;
    display: flex;
  }

  .div-calendar-navigate {
    margin-left: 5%;
  }

  .btn-confirm-delete {
    width: 20% !important;
  }

  .btn-cancel-delete {
    width: 20% !important;
  }
}

@media (max-width: 1024px) {
  .ModalCalendar {
    vertical-align: baseline !important;
  }

  .div-container-right-calendar {
    display: none;
  }

  .div-container-calendar {
    width: 100%;
    height: 98vh;
  }

  .span-calendar-date {
    display: none;
  }

  .div-calendar-navigate {
    display: flex;
  }

  .headerCalendar {
    margin-left: -5px;
    margin-top: 2%;
  }

  .col-btn-headers {
    justify-content: space-between;
    margin-left: 0px;
    display: flex !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .ModalCalendarViewAppoiment {
    vertical-align: super !important;
  }

  .col-header-calendar {
    justify-content: space-between;
  }
}

@media (max-width: 800px) {
  .react-calendar__navigation__label {
    font-size: 0.9rem;
  }
}

.div-calendar-navigate button {
  padding-bottom: 7px !important;
}
