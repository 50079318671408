.list-requests {
    height: 100%;
    overflow: auto;

}

.dt-requests {
    overflow: auto;
    border-radius: 10px;
    padding: 1rem;
}

@media (min-height: 700px) {
    .dt-requests {
        height: 80vh !important;
    }
}

@media (min-height: 600px) {
    .dt-requests {
        height: 72vh;
    }
}

@media (min-width: 1425px) {
    .dt-requests {
        display: flex;
    }
}