.div-calendar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 1024px) {
    .div-calendar-container {
        width: 95%;
    }
}