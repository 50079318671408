@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Avenir+Next:wght@300;400;500;700&display=swap");

.label-register {
  color: #808080;
  font-size: 1rem;
  font-family: "Roboto";
}

.register-form__input {
  display: block;
  width: 100% !important;
  padding: 6px 20px;
  box-sizing: border-box;
  margin-bottom: -8px;
  outline: none;
  font-size: 1rem;
  color: black;
  border-radius: 16px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  margin-top: 6px !important;
  font-family: "Roboto";
}

.btn-login-header {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-medical {
  border: none !important;
  background: none !important;
  font-size: 1.1rem;
  color: black !important;
  font-weight: bold !important;
  font-style: italic !important;
}

.div-container-registration-medical {
  border: 1px solid white;
  padding: 3rem;
  background: white;
  border-radius: 16px;
  min-height: 46rem;
  width: 44rem;
  padding-top: 1.5rem;
  margin-top: 3rem;
}

.div-container-registration-patient {
  border: 1px solid white;
  padding: 3rem;
  background: white;
  border-radius: 16px;
  padding-top: 1rem;
}

.div-container-login-patient {
  border: 1px solid white;
  padding: 3rem;
  background: white;
  border-radius: 16px;
  padding-top: 1rem;
}

.btn-upload-file {
  border-color: #006aff !important;
  color: #006aff !important;
  border-radius: 16px !important;
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 16px !important;
}

.custome-step {
  padding-bottom: 1rem;
}

.required {
  color: red;
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  border: none;
  cursor: pointer;
  border-radius: 60%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 0 -1px 0 rgb(0 0 0 / 4%), 0 1px 1px rgb(0 0 0 / 25%);
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
}

.login-with-facebook-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 60%;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(https://cdn.icon-icons.com/icons2/1826/PNG/512/4202107facebookfblogosocialsocialmedia-115710_115591.png);
  background-size: 22px;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
}

.login-with-apple-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 60%;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(https://cdn.icon-icons.com/icons2/1/PNG/256/social_apple_mac_65.png);
  background-size: 23px;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
}

.registro-name {
  font-weight: 600;
  font-size: 2.1rem;
  font-family: "Roboto";
}

.MuiStepIcon-root.Mui-active {
  color: #64cb8e !important;
}

.MuiStepIcon-root.Mui-completed {
  color: #64cb8e !important;
}

.steperRegisterDoctor {
  padding-bottom: 2rem;
}

.ant-tabs-nav {
  width: 100% !important;
}

.ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
  justify-content: center !important;
}

.ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.row-attach-file {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.div-container-btn-social-networks {
  display: flex;
  justify-content: space-around;
  width: 60%;
}

@media (min-width: 1920px) {
  .registro-name {
    margin-bottom: 2rem;
  }
  .div-container-login-patient {
    max-height: 60vh;
    margin-top: 0px;
  }

  .landing-content {
    align-items: start !important;
  }
}

@media (min-width: 1020px) and (max-width: 1030px) {
  .div-container-login-patient {
    max-height: 60vh;
    margin-top: 0;
  }
  .landing-content {
    align-items: start !important;
  }
}
@media (min-width: 1366px) {
  .registro-name {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1025px) {
  .div-container-registration-patient {
    height: 85vh;
  }

  .div-container-login-patient {
    max-height: 85vh;
  }
}

/* MEDIA QUERY MOBILE  */
@media (max-width: 1024px) {
  .div-container-registration-patient {
    border: 1px solid white;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    width: 100%;
    padding-top: 1rem;
  }

  .div-container-login-patient {
    border: 1px solid white;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    width: 100%;
    padding-top: 1rem;
  }

  .btn-upload-file {
    border-color: #4177c6 !important;
    color: #4177c6 !important;
    border-radius: 16px !important;
  }

  .centerTextRegister {
    margin-top: 3rem !important;
  }

  .div-container-btn-social-networks {
    width: 70%;
  }
}

@media (max-width: 768px) {
}
