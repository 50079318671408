.volver-btn {
  height: 40px !important;
  border: 1px solid #006aff !important;
  background: white !important;
  color: #006aff !important;
  border-radius: 50px !important;
  margin-right: 3% !important;
}

.vivi-modal-btn {
  height: 40px !important;
  border-radius: 50px !important;
  width: 8rem;
}

.btn-ok {
  border: 1px solid #006aff !important;
  color: white !important;
  background: #006aff !important;
  opacity: 80%;
}

.btn-back {
  border: 1px solid #006aff !important;
  color: #006aff !important;
  background: white !important;
  margin-right: 5%;
  opacity: 80%;
}

.btn-back:hover {
  opacity: 100%;
}

.btn-decline {
  border-color: #006aff !important;
  color: #006aff !important;
  opacity: 80%;
}

.btn-decline:hover {
  /* font-weight: bold; */
  opacity: 100%;
}

@media (min-width: 1920px) {
  .volver-btn {
    width: 18% !important;
  }
}

@media (max-width: 1680px) {
  .volver-btn {
    width: 20% !important;
  }
}
