.loaderContainer {
  position: fixed;
  left: 55%;
  top: 37%;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
}

.loader {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}
