.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  padding: 3%;
}

.cookie-content {
  display: flex;
  padding-top: 1.5rem;
  flex-direction: column;
}

.cookie-text {
  font-size: 0.8rem;
  font-weight: 600;
  flex: 1; /* El texto ocupa todo el espacio disponible */
}

.cookie-details {
  font-weight: bold;
  color: #0079bf;
  font-size: 0.9rem;
  padding-top: 1.5rem;
  cursor: pointer;
}

.cookie-details:hover {
  color: #1d6188;
}

.cookie-buttons {
  display: flex;
  gap: 1rem; /* Agrega un espacio uniforme entre los botones */
  padding-top: 2rem;
  justify-content: flex-end;
}

.cookie-accept,
.cookie-reject {
  padding: 0.5rem 1rem; /* Agrega un poco de espacio interno a los botones */
  background-color: #006aff;
  color: #fff;
  border: none;
  cursor: pointer;
  /* font-size: 15px; */
  /* font-weight: bold; */
  transition: background-color 0.3s ease;
}

.cookie-reject {
  background-color: #fff;
  color: #006aff;
  border: 1px solid #006aff;
}

.cookie-reject:hover {
  opacity: 100%;
}

.cookie-accept:hover,
.cookie-reject:hover {
  opacity: 100%;
}

.cookie-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cookie-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.cookie-tab {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
}

.cookie-tab.active {
  color: #0079bf;
  border-bottom: 3px solid #0079bf;
}

.cookie-item {
  margin-bottom: 1.5rem; /* Espaciado mínimo entre cada elemento */
}

.cookie-item h4 {
  font-weight: bold; /* Títulos en negrita */
}

.cookie-item p {
  padding-left: 2rem; /* Espaciado del lado derecho del contenido */
  font-size: 0.8rem;
}

.button-cookie {
  border-radius: 100px;
  opacity: 80%;
  font-size: initial;
}

@media screen and (max-width: 768px) {
  .cookie-content {
    flex-direction: column;
    align-items: flex-start; /* Alinea el contenido a la izquierda en dispositivos móviles */
  }

  .cookie-details {
    margin-left: 0; /* Elimina el margen en dispositivos móviles */
    margin-top: 1rem; /* Agrega un espacio superior en dispositivos móviles */
  }
}
