.insertFile {
  border: 1px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f0eded;
  border-radius: 10px;
  height: 20vh;
  cursor: pointer;
}

@media (max-width: 1680px) {
  .btn-upload-file {
    padding: 4px !important;
  }
}
