table.separado {
    /* font-size: 20px; */
    width: 100%;
}

.separado {
    /* border: 1px solid black; */
    background-color: #F0F2F5;
    border-radius: 10px;
}

.separado tbody {
    text-align: start;
}

.separado thead th {
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    text-align: start;
}

.btn-datatable-mobile input {
    background-color: #F0F2F5;
}

.btn-heigth {
    height: 32px !important;
}

.btn-background-color-vivi {
    background-color: #006AFF !important;
}

.btn-search-dt {
    background-color: #fff !important;
    width: 80% !important;
}

@media (max-height: 1366px) {
    .btn-datatable-mobile {
        padding-top: 2rem;
    }
}