.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  border: none;
  border-radius: 77%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  box-shadow: 0 -1px 0 rgb(0 0 0 / 4%), 0 1px 1px rgb(0 0 0 / 25%);
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.login-with-facebook-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 77%;
  color: #757575;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(https://www.freeiconspng.com/uploads/facebook-f-logo-transparent-facebook-f-22.png);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.login-with-facebook-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-facebook-btn:active {
  background-color: #eeeeee;
}

.login-with-facebook-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-facebook-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.login-with-apple-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-apple-btn:active {
  background-color: #eeeeee;
}

.login-with-apple-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-apple-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.names-btn {
  display: grid;
  margin-right: auto;
  padding-left: 2rem;
}

.name-doctor {
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 1.5rem;
  text-transform: capitalize;
}

.specilitie-doctor {
  font-size: 1rem;
  font-weight: 400;
  color: #006aff;
}

.counter-hour {
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  color: #7c7c81;
}

.login-form__input {
  display: block;
  width: 100% !important;
  padding: 6px 20px;
  box-sizing: border-box;
  margin-bottom: -8px;
  outline: none;
  font-size: 1rem;
  color: black !important;
  border-radius: 16px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  margin-top: 6px !important;
}

.label-login {
  color: #808080;
  font-size: 1rem;
  font-family: "Roboto";
}

.div-btn-medical {
  justify-content: center;
  position: absolute;
  margin-top: 6rem;
  cursor: pointer;
}

.btn-medical-login {
  border: none !important;
  background: none !important;
  font-size: 1rem;
  color: black !important;
  font-weight: bold !important;
  font-style: italic !important;
  cursor: pointer;
}

.btn-medical-login:hover {
  color: #4177c6 !important;
}

.div-container-login {
  border: 1px solid white;
  background: #fff;
  border-radius: 5px;
  width: 100%;
}

.div-btn-registration {
  justify-content: center;
  font-family: "Roboto";
}

.signIn__button {
  background: #006aff !important;
  width: 100% !important;
  border-radius: 50px !important;
  height: 40px !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: white !important;
  margin-top: 5%;
}

@media (min-width: 1920px) {
  .div-container-login {
    padding: 3rem;
  }

  .div-btn-registration {
    margin-top: 8%;
  }
}

@media (min-width: 1366px) {
  .div-container-login {
    padding: 1rem;
  }

  .div-btn-registration {
    margin-top: 6%;
  }
}

@media (max-width: 1155px) {
  .names-btn {
    padding-left: 0.5rem;
  }

  .name-doctor {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .specilitie-doctor {
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }

  .counter-hour {
    margin-right: 0.5rem;
  }

  .div-btn-registration {
    margin-top: 4%;
  }
}

@media (max-width: 1500px) {
  .names-btn {
    padding-left: 0.5rem;
  }

  .name-doctor {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .specilitie-doctor {
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }

  .counter-hour {
    margin-right: 0.5rem;
  }

  .div-btn-registration {
    margin-top: 4%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1110px) {
  .name-doctor {
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 0px;
    text-transform: capitalize;
  }
}
