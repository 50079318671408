.buttonOkModal {
  border: none !important;
  font-weight: bold !important;
  color: #006aff !important;
  text-transform: uppercase !important;
}

.span-modal {
  color: rgba(0, 0, 0, 0.35);
  font-size: 1rem;
  font-weight: 400;
}

.input-modal {
  font-weight: 400;
  font-size: 1.3rem;
  color: #000000;
}
