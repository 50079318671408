.volver-btn {
  width: 151px !important;
  height: 40px !important;
  border: 1px solid #006aff !important;
  background: white !important;
  color: #006aff !important;
  border-radius: 50px !important;
  margin-right: 5px !important;
}

.btnConfirmChange {
  width: 18% !important;
  height: 40px !important;
  border: 1px solid #006aff !important;
  background: white !important;
  color: #fff !important;
  border-radius: 50px !important;
  background-color: #006aff !important;
}

.ant-modal .ant-modal-content .ant-modal-header {
  padding: 6% 7%;
}

.ant-modal .ant-modal-content .ant-modal-body {
  padding: 2% 7% 4% 7%;
}

.login-form__input_change_password {
  display: block;
  width: 65% !important;
  padding: 6px 20px;
  box-sizing: border-box;
  margin-bottom: -8px;
  outline: none;
  font-size: 1rem;
  color: black !important;
  border-radius: 16px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  margin-top: 6px !important;
}
