@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn {
  font-family: "Roboto";
}

.ant-space-item{
  font-family: "Roboto";
}

h4 {
  font-family: "Roboto";
  font-weight: 500;
}

span {
  font-family: "Roboto";
}

label{
  font-family: "Roboto";
}
p{
  font-family: "Roboto";
}

.ant-tabs-tab-btn {
  font-family: "Roboto";

}

.MuiStepLabel-label {
  font-family: "Roboto";
}
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #64CB8E!important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #64CB8E!important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #64CB8E!important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #64CB8E!important;
}

.ant-card-body {
  padding: 0px!important;
}